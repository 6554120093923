import { OnInit, Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'hd-roofing';
  year : Number  =new Date().getFullYear();

  @ViewChild("serviceBox", {static: false}) public serviceBox: ElementRef;

  scrollToElement() {
   setTimeout(() => {
          this.serviceBox.nativeElement.scrollIntoView({behavior: 'smooth'})
      });
  }

  constructor() {

  }




  ngOnInit() {
    AOS.init();



  }  
  ngAfterViewInit(){

  document.addEventListener('scroll', function () {
  // console.log('wed');

  var bg = document.getElementsByClassName('parallax');


  Array.from(bg).forEach(e => {
    // console.log('wed');
 
        if (e instanceof HTMLElement){
     
         let yPos;
          // window.innerWidth;
         
          if ((window.screen.width ) < 1450){
            // console.log(window.screen.width )
            yPos = -(window.pageYOffset / 4) + (window.screen.height / 4 );
          }else{
            yPos = -(window.pageYOffset / 2) + (window.screen.height * .25);

          }
          // console.log(yPos)
          // let blur = yPos + 1000 / 100;
          var coords = `50% ${yPos}px`;
          // filter: blur(yPos);
          // var s = window.scrollY;
          // var d = window.scrollX;
          // var c = window.screen.height;

          // // scroll value and opacity
          // var scrollPercent = 50 - window.pageYOffset  / 25;
          // let blur = (s / 880);
          // console.log(scrollPercent);
          // e.style.filter= `blur(${scrollPercent}%)`;
          // e.style.backgroundSize = "";
                e.style.backgroundPosition = coords;
                // console.log('wed');
        }
  });
      // parallax();
  
  });
    var makeItRain = function() {
      //clear out everything
      // $('.rain').empty();
    
      var increment = 0;
      var drops = "";
      var backDrops = "";
    
      while (increment < 100) {
        //couple random numbers to use for various randomizations
        //random number between 98 and 1
        var randoHundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1));
        //random number between 5 and 2
        // var randoFiver = (Math.floor(Math.random() * (5 - 2 + 1) + 2));
        var randoFiver = (Math.floor(Math.random() * (10 - 2 + 1) + 2));
        //increment
        increment += randoFiver;
        //add in a new raindrop with various randomizations to certain CSS properties
        drops += '<div class="drop" style="left: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
        backDrops += '<div class="drop" style="right: ' + increment + '%; bottom: ' + (randoFiver + randoFiver - 1 + 100) + '%; animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"><div class="stem" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div><div class="splat" style="animation-delay: 0.' + randoHundo + 's; animation-duration: 0.5' + randoHundo + 's;"></div></div>';
      }
    document.querySelector('.rain').innerHTML  = drops;
    // document.querySelector<HTMLElement>('#clouds').style.display = '  block'
    setTimeout(function() {
      
      document.querySelector('body').classList.add('lightning');
      // document.querySelector<HTMLElement>('#main-toolbar').style.boxShadow = '0px 2px 708px 0px  white '
      
      // document.querySelector('body').style.background = ' linear-gradient(to bottom, #CCC 1% , #000 99% )'

      // document.querySelector('body').style.background = ' linear-gradient(to bottom, #444 50% , #000 50% )'
      // document.querySelector('body').style.backgroundRepeat = '  no-repeat'
   
      setTimeout(function() {
        // document.querySelector<HTMLElement>('.toolbar').style.boxShadow = 'box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.5);'
              // document.querySelector('body').style.background = ' linear-gradient(to bottom, #0b0d0d  , #000)'
              // document.querySelector('body').style.backgroundRepeat = '  no-repeat'
      }, 50);

    }, 1500);
    // console.clear();
    }
    

 
    makeItRain();
    // console.clear();
  }



}
